<template>
  <div>
    <b-card
      no-body
      class="mb-0"
    >
      <div class="m-2">
        <!-- Table Top -->
        <b-row>
          <!-- Per Page -->
          <b-col
            cols="12"
            md="4"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>Mostrando</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="pageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label>registros</label>
          </b-col>

          <!-- Search -->
          <b-col
            cols="12"
            md="8"
          >
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input
                v-model="query"
                class="d-inline-block mr-1"
                placeholder="Buscar..."
              />
              <!-- <v-select
                v-model="statusFilter"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                label="descripcion"
                :options="statusOptions"
                class="invoice-filter-select d-inline-block mr-1"
                placeholder="Filtros Ose"
                @input="chageStatusFilter"
              /> -->
              <b-button
                variant="primary"
                @click="openForm('new')"
              >
                <span class="text-nowrap">Nuevo</span>
              </b-button>
            </div>
          </b-col>
        </b-row>

      </div>

      <b-table
        ref="refUserListTable"
        class="position-relative"
        :items="items"
        responsive
        :fields="fields"
        primary-key="id"
        :sort-by.sync="sortBy"
        show-empty
        empty-text="No se encontraron registros"
        :busy="isBusy"
      >
        <template #table-busy>
          <div class="text-center text-primary my-2">
            <b-spinner
              variant="primary"
              class="align-middle"
            />
            <strong class="ml-1">Cargando...</strong>
          </div>
        </template>
        <template #cell(razonSocial)="data">
          <b-media vertical-align="center">
            <template #aside>
              <b-avatar
                size="32"
                :src="`data:image/jpeg;base64,${data.item.foto}`"
                :text="avatarText(`${data.item.razonSocial}`)"
                :variant="`light-${getColorAvatar()}`"
              />
            </template>
            <span class="font-weight-bold d-block text-nowrap">
              {{ data.item.razonSocial }}
            </span>
            <small class="text-muted">{{ data.item.representanteLegal }}</small>
            <small class="text-muted d-block ">{{ data.item.tipoDocumento.abreviatura }} {{ data.item.documento }}</small>
          </b-media>
        </template>
        <!-- Column: estado_sunat -->
        <template #cell(estado_sunat)="data">
          <b-badge
            v-if="data.item.repuestaOse == null"
            variant="primary"
          >
            Pendiente
          </b-badge>
          <b-badge
            v-if="data.item.repuestaOse != null"
            variant="success"
          >
            Enviado
          </b-badge>

        </template>
        <!-- Column: estado_venta -->
        <template #cell(estado_venta)="data">
          <b-badge
            v-if="data.item.fechaAnulacion == null"
            variant="success"
          >
            Vigente
          </b-badge>
          <b-badge
            v-if="data.item.fechaAnulacion != null"
            variant="danger"
          >
            Anulado
          </b-badge>

        </template>
        <template #cell(comprobanteTemplate)="data">
          <div class="d-flex align-items-center">
            <div>
              <div class="font-small-4 font-weight-bolder">
                {{ data.item.nroComprobante }}
              </div>
              <div class="font-small-3 text-muted">
                Fecha : {{ data.item.fechaEmision }}
              </div>
            </div>
          </div>
        </template>
        <template #cell(proveedorTemplate)="data">
          <div class="d-flex align-items-center">
            <div>
              <div class="font-small-3 font-weight-bolder">
                {{ data.item.proveedor.razonSocial }}
              </div>
              <div class="font-small-2 text-muted">
                RUC: {{ data.item.proveedor.ruc }}
              </div>
            </div>
          </div>
        </template>
        <template #cell(transportistaDenominacion)="data">
          <div class="d-flex align-items-center">
            <div>
              <div class="font-small-3 font-weight-bolder">
                {{ data.item.transportistaDenominacion }}
              </div>
              <div class="font-small-2 text-muted">
                RUC: {{ data.item.transportistaDocumentoNumero }}
              </div>
            </div>
          </div>
        </template>
        <template #cell(etapa)="data">
          <template v-if="data.item.etapa === 'ACEPTADO'">
            <b-badge
              pill
              variant="light-success"
            >
              {{ data.item.etapa.toUpperCase() }}
            </b-badge>
          </template>
          <template v-if="data.item.etapa === 'RECHAZADO'">
            <b-badge
              pill
              variant="light-danger"
            >
              {{ data.item.etapa.toUpperCase() }}
            </b-badge>
          </template>
          <template v-if="data.item.etapa === 'CREADO'">
            <b-badge
              pill
              variant="light-primary"
            >
              {{ data.item.etapa.toUpperCase() }}
            </b-badge>
          </template>
          <template v-if="data.item.etapa === 'ENVIADO'">
            <b-badge
              pill
              variant="light-warning"
            >
              {{ data.item.etapa.toUpperCase() }}
            </b-badge>
          </template>
        </template>
        <template #cell(total)="data">
          <div class="font-small-3">
            {{ formatearMoneda(data.item.total, data.item.moneda) }}
          </div>
        </template>
        <template #cell(totalIgv)="data">
          <div class="font-small-3">
            {{ formatearMoneda(data.item.totalIgv, data.item.moneda) }}
          </div>
        </template>
        <template #cell(totalFlete)="data">
          <div class="font-small-3">
            {{ formatearMoneda(data.item.totalFlete, data.item.moneda) }}
          </div>
        </template>
        <template #cell(subTotal)="data">
          <div class="font-small-3">
            {{ formatearMoneda(data.item.subTotal, data.item.moneda) }}
          </div>
        </template>
        <!-- Column: Actions -->
        <template #cell(acciones)="data">
          <b-dropdown
            variant="link"
            no-caret
            :right="$store.state.appConfig.isRTL"
          >

            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>
            <b-dropdown-item
              @click="getPrintPdf(data)"
            >
              <feather-icon icon="PrinterIcon" />
              <span class="align-middle ml-50">VER PDF</span>
            </b-dropdown-item>
            <b-dropdown-item
              v-if="data.item.etapa==='CREADO'"
              @click="changeStatusPedidoCompra(data,'ENVIADO','CREADO -> ENVIADO')"
            >
              <feather-icon icon="SendIcon" />
              <span class="align-middle ml-50">ENVIAR</span>
            </b-dropdown-item>
            <b-dropdown-item
              v-if="data.item.etapa==='CREADO'"
              @click="getDeletePedidoCompra(data)"
            >
              <feather-icon icon="TrashIcon" />
              <span class="align-middle ml-50">ELIMINAR</span>
            </b-dropdown-item>
            <b-dropdown-item
              v-if="data.item.etapa==='ENVIADO'"
              @click="changeStatusPedidoCompra(data,'ACEPTADO','ENVIADO -> ACEPTADO')"
            >
              <feather-icon icon="SendIcon" />
              <span class="align-middle ml-50">ACEPTAR</span>
            </b-dropdown-item>
            <b-dropdown-item
              v-if="data.item.etapa==='ENVIADO'"
              @click="changeStatusPedidoCompra(data,'RECHAZADO','ENVIADO -> RECHAZADO')"
            >
              <feather-icon icon="SendIcon" />
              <span class="align-middle ml-50">RECHAZAR</span>
            </b-dropdown-item>
            <b-dropdown-item
              v-if="data.item.etapa!=='ACEPTADO' && data.item.etapa!=='RECHAZADO'"
              @click="getEdit(data)"
            >
              <feather-icon icon="EditIcon" />
              <span class="align-middle ml-50">EDITAR</span>
            </b-dropdown-item>
            <!-- <b-dropdown-item
                v-if="data.item.repuestaOse != null && data.item.fechaAnulacion == null"
                @click="generarGuia(data)"
              >
                <feather-icon icon="FileTextIcon" />
                <span class="align-middle ml-50">Generar Guia</span>
              </b-dropdown-item>
              <b-dropdown-item
                v-if="data.item.repuestaOse != null && data.item.fechaAnulacion == null"
                @click="generarNota(data)"
              >
                <feather-icon icon="BookIcon" />
                <span class="align-middle ml-50">Generar Nota</span>
              </b-dropdown-item> -->
            <!-- b-dropdown-item
              v-if="data.item.repuestaOse == null"
              target="_blank"
              :href="data.item.repuestaOse == null ?'':data.item.repuestaOse.rutaPdf"
              @click="getConsultaOse(data)"
            >
              <feather-icon icon="PrinterIcon" />
              <span class="align-middle ml-50">Consultar Ose</span>
            </b-dropdown-item -->

            <!-- <b-dropdown-item @click="getEdit(data)">
                <feather-icon icon="FileTextIcon" />
                <span class="align-middle ml-50">Ver</span>
              </b-dropdown-item>

              <b-dropdown-item @click="getDelete(data)">
                <feather-icon icon="TrashIcon" />
                <span class="align-middle ml-50">Eliminar</span>
              </b-dropdown-item> -->
          </b-dropdown>
        </template>

      </b-table>
      <div class="mx-2 mb-2">
        <b-row>

          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">Mostrando {{ from }} a {{ to }} de {{ of }} registros</span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >

            <b-pagination
              v-model="page"
              :total-rows="totalItems"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>

          </b-col>

        </b-row>
      </div>
    </b-card>
    <b-modal
      ref="my-modal"
      size="lg"
      hide-footer
      title=""
      no-close-on-backdrop
      no-close-on-esc
    >
      <div class="d-block">
        <h4 class="text-center">
          COTIZACIONES</h4>
        <b-card
          class="card-transaction"
          no-body
        >
          <b-card-body>
            <b-row class="mb-2">
              <b-col
                cols="12"
                md="7"
                class="mb-1 mb-md-0"
              >
                <b-form-group
                  label="Proveedor"
                  label-for="proveedor"
                >
                  <v-select
                    v-model="preProveedor"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    label="nombreComercial"
                    :options="selectProveedor"
                  />
                </b-form-group>
              </b-col>
              <b-col
                cols="12"
                md="4"
                class="mb-1 mb-md-0"
              >
                <b-form-group
                  label="Adjuntar Pdf"
                  label-for="archivo pdf"
                >
                  <b-form-file
                    id="fileToUpload"
                    v-model="fileToUpload"
                    name="fileToUpload"
                    accept="text/pdf"
                    placeholder="Seleccione archivo"
                    drop-placeholder="Drop file here..."
                    @change="fileUploaded"
                  />
                </b-form-group>
              </b-col>
              <b-col
                md="1"
                class="mt-2"
              >
                <b-button
                  variant="primary"
                  class="btn-icon"
                  size="sm"
                  @click="addCotizacionDetalle"
                >
                  <feather-icon icon="PlusSquareIcon" />
                </b-button>
              </b-col>
            </b-row>
            <div
              v-for="(transaction, index) in transactionData"
              :key="index"
              class="transaction-item"
            >
              <b-media no-body>
                <b-media-aside>
                  <button
                    type="button"
                    class="btn p-0 border-0 bg-transparent"
                    @click="getCotizacion(transaction)"
                  >
                    <b-avatar
                      rounded
                      size="42"
                      :variant="transaction.avatarVariant"
                    >
                      <feather-icon
                        size="18"
                        :icon="transaction.avatar"
                      />
                    </b-avatar>
                  </button>
                </b-media-aside>
                <b-media-body>
                  <h6 class="transaction-title">
                    {{ transaction.mode }}
                  </h6>
                  <small>{{ transaction.types }}</small>
                </b-media-body>
              </b-media>
              <div
                class="font-weight-bolder"
                :class="transaction.deduction ? 'text-danger':'text-success'"
              >
                {{ transaction.payment }}
                <button
                  type="button"
                  class="btn p-0 border-0 bg-transparent btn-xs"
                  @click="deleteCotizacion(transaction)"
                >
                  <b-avatar
                    rounded
                    size="42"
                    variant="danger"
                  >
                    <feather-icon
                      size="18"
                      icon="TrashIcon"
                    />
                  </b-avatar>
                </button>
              </div>
            </div>
          </b-card-body>
        </b-card>
      </div>
    </b-modal>
  </div>
</template>

<script>

import {
  BCard, BRow, BCol, BFormInput, BButton, BTable, BDropdown, BDropdownItem, BPagination, BSpinner, BAvatar, BMedia, VBTooltip, BBadge, BCardBody, BMediaAside, BMediaBody, BFormFile, BFormGroup,
} from 'bootstrap-vue'
import { jsPDF } from 'jspdf'
import vSelect from 'vue-select'
import store from '@/store'
import { showToast, showError } from '@/helpers'
import { avatarText } from '@core/utils/filter'
import moment from 'moment'
// import { params } from 'vee-validate/dist/types/rules/alpha'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BDropdown,
    BDropdownItem,
    BPagination,
    BBadge,
    vSelect,
    BSpinner,
    BAvatar,
    BMedia,
    BCardBody,
    BMediaAside,
    BMediaBody,
    BFormFile,
    BFormGroup,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },

  data: () => ({
    isBusy: false,
    idEmpresa: 3,
    isToggleSidebar: false,
    dataEdit: {},
    preProveedor: null,
    fileToUpload: null,
    formType: '',
    page: 1,
    perPage: 10,
    totalRows: 0,
    totalItems: 0,
    totalPage: 0,
    from: 0,
    to: 0,
    of: 0,
    pageOptions: [10, 20, 50],
    sortBy: null,
    pedidoSelected: null,
    sortDesc: false,
    sortDirection: 'asc',
    hideModal: false,
    query: '',
    items: [],
    selectProveedor: [],
    statusFilter: { id: '-1', descripcion: 'Todos' },
    statusOptions: [
      { id: '-1', descripcion: 'Todos' },
      { id: '1', descripcion: 'Enviados' },
      { id: '2', descripcion: 'Pendientes' },
      { id: '3', descripcion: 'Vigentes' },
      { id: '4', descripcion: 'Anulados' },
    ],
    userProfile: localStorage.getItem('userProfile'),
    fields: [
      { key: 'idPedidoCompra', sortable: true, thClass: 'd-none', tdClass: 'd-none' },
      { key: 'fechaRegistro', label: 'FECHA REGISTRO', sortable: true, tdClass: 'w150', thClass: 'w150' },
      { key: 'codigo', label: 'CODIGO' },
      { key: 'pedido.almacen.descripcion', label: 'ALMACEN' },
      { key: 'pedido.codigo', label: 'PEDIDO COMPRA', sortable: true, tdClass: 'w180', thClass: 'w180' },
      // { key: 'nroComprobante', label: 'Nro Comprobante' },
      // { key: 'justificacion', label: 'JUSTIFIACION', sortable: true },
      { key: 'subTotal', label: 'SUB TOTAL' },
      { key: 'total', label: 'TOTAL' },
      { key: 'etapa', label: 'ETAPA' },
      { key: 'acciones' },
    ],
    transactionData: [
      {
        mode: 'Amazon Web Services, Inc. Invoice',
        types: '437356434540',
        avatar: 'PrinterIcon',
        avatarVariant: 'light-success',
        payment: '10/01/2025',
        deduction: false,
        pdf: '/documentos/compra/COMPRA_2_1736512125959.pdf',
      },
      {
        mode: 'RIOS CHAVEZ CARLOS TACIANO',
        types: '10439286381',
        avatar: 'PrinterIcon',
        avatarVariant: 'light-success',
        payment: '12/01/2025',
        deduction: false,
        pdf: '/documentos/compra/COMPRA_1_1736511475451.pdf',
      },
    ],
  }),
  watch: {
    perPage: {
      handler() {
        this.listarRegistros()
      },
    },
    page: {
      handler() {
        this.listarRegistros()
      },
    },
    query: {
      handler() {
        this.listarRegistros()
      },
    },
  },
  mounted() {
    this.listarRegistros()
  },
  methods: {
    async addCotizacionDetalle() {
      console.log(this.preProveedor)
      try {
        if (this.preProveedor === null) {
          throw new Error('TIENE QUE SELECCIONAR UN PROVEEDOR')
        }
        if (this.fileToUpload === null) {
          throw new Error('TIENE QUE SELECCIONAR UN ARCHIVO')
        }
        if (this.pedidoSelected.resumenCotizacion.length === 3) {
          throw new Error('TIENE YA TIENE 3 COTIZACIONES INGRESADAS')
        }
        const formCotizacion = {
          estado: true,
          etapa: this.pedidoSelected.etapa,
          ganador: false,
          pedido: { idPedidoCompra: this.pedidoSelected.idPedidoCompra },
          proveedor: { idProveedor: this.preProveedor.idProveedor },
        }
        console.log(formCotizacion)
        let idCotizacion = 0
        await store
          .dispatch('logistica/COTIZACION_LOGISTICA_CREATE', formCotizacion)
          .then(response => {
            if (response) {
              console.log(response.result)
              idCotizacion = response.result.idCotizacionPedido
            }
          })
          .catch(error => {
            this.showToast('Ha ocurrido un error', error, 'danger')
            this.permisoAlmacen = false
            this.almacen = null
          })
        // console.log(this.pedidoSelected)
        const file1 = document.getElementById('fileToUpload').files[0]
        // console.log(file1)
        const formDataUpload = new FormData()
        formDataUpload.append('file', file1)
        formDataUpload.append('idMaster', idCotizacion)
        formDataUpload.append('tabla', 'cotizacion')
        await store
          .dispatch('logistica/DOCUMENTO_UPLOAD_CREATE', formDataUpload)
          .then(response => {
            if (response) {
              console.log(response)
              this.$refs['my-modal'].hide()
              this.listarRegistros()
            }
          })
          .catch(error => {
            this.showToast('Ha ocurrido un error', error, 'danger')
            this.permisoAlmacen = false
            this.almacen = null
          })
        this.preProveedor = null
      } catch (error) {
        this.showToast('Ha ocurrido un error', error.message, 'danger')
      }
    },
    fileUploaded() {

    },
    async deleteCotizacion(data) {
      console.log(data)
      const service = 'logistica/COTIZACION_LOGISTICA_DELETE'
      await store.dispatch(service, { id: data.cotizacion.idcotizacion })
        .then(response => {
          console.log(response)
          this.showToast('Operación Extitosa', 'Se elimino el pedido de compra', 'success')
          this.$refs['my-modal'].hide()
          this.listarRegistros()
        })
        .catch(error => {
          console.log(error)
          this.showToast('Operación Error', error.errorMessage, 'danger')
        })
    },
    getPrintPdf(data) {
      console.log(data.item)
      const row = data.item
      // eslint-disable-next-line new-cap
      const doc = new jsPDF('p', 'pt', 'a4', true)

      doc.setProperties({
        title: `PEDIDO DE COMPRA N° ${row.codigo}`,
        author: 'UNIGAS',
        creator: 'UNIGAS',
      })

      // const totalPagesExp = '{total_pages_count_string}'
      const img = row.pedido.almacen.logo
      doc.setFont('arial')
      doc.addImage(img, 'JPEG', 20, 7, 140, 60)
      doc.setFontSize(12)
      doc.text(` ${row.pedido.almacen.descripcion}`, 170, 20)
      doc.setFontSize(8)
      doc.text('Telef. 042-526544 Cel. 970089487', 170, 32)
      doc.text('DIRECCION: JR. FRANCISCO PIZARRO NRO. 860', 170, 43)
      doc.text('RUC: 20362013802', 170, 53)

      doc.setFontSize(12)
      doc.text(`ORDEN COMPRA N°: ${row.codigo}`, 20, 80)
      // doc.text('GUIA N°:', 400, 80)
      doc.setFontSize(9)
      doc.text(`USUARIO: ${row.pedido.trabajador.nombreCompleto}`, 320, 80)
      doc.text(`PEDIDO COMPRA N°: ${row.pedido.codigo}`, 20, 100)
      doc.text(`PROVEEDOR: ${row.proveedor.razonSocial} - ${row.proveedor.ruc}`, 180, 100)
      // doc.text('Forma Pago: ', 470, 100)
      doc.text(`F. REGISTRO: ${row.fechaRegistro}`, 20, 120)
      doc.text(`F. RESPUESTA: ${row.fechaRespuesta == null ? '' : row.fechaRespuesta}`, 180, 120)
      doc.text(` ETAPA: ${row.etapa}`, 320, 120)
      doc.text(` ITEMS(s): ${row.resumen.length}`, 520, 120)
      doc.setFontSize(9)
      doc.text('----------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------', 5, 130)
      doc.text('PRODUCTO', 20, 140)
      // doc.text('UND', 334, 140)
      // doc.text('CANT', 365, 140)
      doc.text('UND', 400, 140)
      doc.text('CANT', 445, 140)
      doc.text('P.COMP', 490, 140)
      doc.text('SUBTOTAL', 530, 140)
      doc.text('----------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------', 5, 150)
      let initr = 160
      for (let i = 0; i < row.resumen.length; i += 1) {
        const name = (row.resumen[i].producto).substring(0, 100)
        doc.setFontSize(7.5)
        doc.text(name, 20, initr)
        doc.setFontSize(8.5)
        // doc.text(row.resumen[i].unidadmedida.substring(0, 4), 335, initr)
        // doc.text(`${row.resumen[i].cantidad_solicitada}`, 366, initr)
        doc.text(`${row.resumen[i].unidadmedida.substring(0, 4)}`, 400, initr)
        doc.text(`${parseFloat(row.resumen[i].cantidad).toFixed(2)}`, 445, initr)
        doc.text(`${parseFloat(row.resumen[i].precio).toFixed(2)}`, 490, initr)
        doc.text(`${parseFloat(row.resumen[i].subtotal).toFixed(2)} `, 530, initr)
        initr = Number(initr) + 13
        if (initr + 13 >= 813) {
          doc.addPage()
          initr = 35
        }
      }

      doc.setFontSize(9)

      initr = Number(initr) + 15
      if (initr + 13 >= 813) {
        doc.addPage()
        initr = 35
      }
      doc.text('OBSERVACION:', 20, initr)
      doc.text(`${row.observacion == null ? '' : row.observacion}`, 20, initr + 13)

      /* $lineas_observaciones = data[0].observacion.split('<br>');
        $tam_observaciones = $lineas_observaciones.length;
        for ($j=0; $j < $tam_observaciones ; $j++) {
            $texto_observacion = $lineas_observaciones[$j];
            doc.text($texto_observacion, 45, initr);
            initr=parseInt(initr) + 13;
        }
           */
      /*  var tipoigv = '' ;
      if(data[0].tipo_igv==0){       tipoigv = '* SIN IGV' ; }
      else if(data[0].tipo_igv==1){  tipoigv = '* IGV INCLUIDO' ;  }
      else if(data[0].tipo_igv==2){  tipoigv = '* IGV INAFECTO' ;             }
      doc.text(tipoigv +"      ", 20, initr + 30); */
      doc.setFontSize(11)
      const simbolMoneda = 'S/' // row.codmoneda === 'PEN' ? 'S/' : '$/'
      doc.text(` Sub total ${simbolMoneda} ${parseFloat(row.subTotal).toFixed(2)}  |   Total ${simbolMoneda} ${parseFloat(row.total).toFixed(2)}`, 20, initr + 40)
      /* if (row.etapa === 'ACEPTADO') {
        const { firma } = row.pedido.trabajador
        if (firma !== null && firma !== '') {
          doc.addImage(firma, 'JPEG', 155, initr + 100, 100, 50)
        }
        doc.text('------------------------------', 150, initr + 150)
        doc.text('SOLICITANTE', 165, initr + 160)

        doc.text('------------------------------', 350, initr + 150)
        doc.text('GERENTE', 370, initr + 160)
      } */
      // doc.putTotalPages(totalPagesExp)
      const blob = doc.output('blob')
      window.open(URL.createObjectURL(blob))
    },
    async getCotizacion(data) {
      console.log(data)
      const baseUrl = 'http://localhost:8087/api-erpunigas/api/v1/logistica/documento/ver_documento'
      const filePath = encodeURIComponent(data.pdf)
      const token = localStorage.getItem('accessToken') // Asegúrate de reemplazar esto con el token real
      try {
        const response = await fetch(`${baseUrl}?filePath=${filePath}`, {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${token}`, // Token en encabezado
          },
        })

        if (response.ok) {
          const blob = await response.blob()
          const url = URL.createObjectURL(blob)
          window.open(url, '_blank') // Abre el archivo descargado en una nueva pestaña
        } else {
          console.error('Error al abrir el documento')
        }
      } catch (error) {
        console.error('Error en la solicitud:', error)
      }
    },
    async listarRegistros() {
      console.log(this.userProfile)
      this.almacen = { idAlmacen: -1 }
      if (this.userProfile !== 'GERENTE OPERACIONES') {
        const dataUser = JSON.parse(localStorage.getItem('userData'))
        await store
          .dispatch('logistica/ALMACEN_FIND_BY_ID_TRABAJADOR', { idTrabajador: dataUser.idTrabajador,
          })
          .then(response => {
            if (response) {
              this.almacen = response.result
              this.permisoAlmacen = true
            }
          })
          .catch(error => {
            this.showToast('Ha ocurrido un error', error, 'danger')
            this.permisoAlmacen = false
            this.almacen = null
          })
      }
      this.isBusy = true
      await store
        .dispatch('logistica/ORDEN_COMPRA_LOGISTICA_FIND_ALL', {
          tipo: 'grilla',
          limit: this.perPage,
          query: this.query,
          page: this.page,
          almacen: -1,
          sortBy: '',
          idAlmacen: this.almacen.idAlmacen,
        })
        .then(response => {
          this.items = response.items
          this.totalRows = response.totalRows
          this.totalItems = response.totalItems
          this.totalPage = response.totalPage
          this.from = this.perPage * (this.page - 1) + (this.totalRows ? 1 : 0)
          this.to = this.perPage * (this.page - 1) + this.totalRows
          this.of = this.totalItems
        })
        .catch(error => {
          this.showToast('Ha ocurrido un error', error, 'danger')
        })
      await store
        .dispatch('logistica/PROVEEDOR_FIND_ALL', { limit: 1000,
          query: '',
          page: 1,
          sortBy: 'idProveedor%7CDESC',
          tipo: 'grilla',
        })
        .then(response => {
          if (response) {
            console.log(response)
            this.selectProveedor = response.items
          }
        })
        .catch(error => {
          this.showToast('Ha ocurrido un error', error, 'danger')
          this.permisoAlmacen = false
          this.almacen = null
        })
      this.isBusy = false
    },
    setCotizciones(data) {
      this.pedidoSelected = data.item
      this.transactionData = []
      this.pedidoSelected.resumenCotizacion.forEach(cotiz => {
        const rowCotiz = {
          mode: cotiz.proveedor,
          types: cotiz.ruc,
          avatar: 'PrinterIcon',
          avatarVariant: 'light-success',
          payment: cotiz.fecharegistro,
          deduction: false,
          pdf: cotiz.url,
          cotizacion: cotiz,
        }
        this.transactionData.push(rowCotiz)
      })
      this.$refs['my-modal'].show()
    },
    openForm(type) {
      // this.formType = type
      // this.isToggleSidebar = true
      console.log(type)
      this.$router.push({ name: 'rt-logistica-ordencompraform', params: { dataEdit: '' } })
    },
    getEdit(data) {
      // this.formType = 'edit'
      // this.isToggleSidebar = true
      // console.log(data.item)
      // this.dataEdit = data.item
      console.log(data.item)
      this.$router.push({ name: 'rt-logistica-pedidocompraform', params: { dataEdit: JSON.stringify(data.item) } })
    },
    changeStatusPedidoCompra(data, etapa, cambio) {
      console.log(data)
      if (data.item.resumenCotizacion.length < 3) {
        this.showToast('Operación Error', 'TIENE QUE INGRESAR LAS 3  COTIZACIONES', 'danger')
        return
      }
      const paramsSwal = {
        title: `${cambio} ?`,
        text: 'Cambiar la etapa ',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Sí',
        cancelButtonText: 'No',
        customClass: {
          confirmButton: 'btn btn-danger',
          cancelButton: 'btn btn-primary ml-1',
        },
        inputValidator: value => {
          if (!value) {
            return 'El campo no puede estar vacío'
          }
          return null
        },
        buttonsStyling: false,
      }
      if (etapa === 'RECHAZADO') {
        paramsSwal.input = 'text'
        paramsSwal.text = ''
        paramsSwal.inputPlaceholder = 'Motivo del rechazo'
      }
      this.$swal(paramsSwal).then(result => {
        if (result.value) {
          this.$swal({
            title: 'Cambiando Etapa Pedido de Compra',
            showCancelButton: false,
            showConfirmButton: false,
            allowOutsideClick: false,
            allowEscapeKey: false,
            onOpen: async () => {
              this.$swal.showLoading()
              const service = 'logistica/PEDIDO_COMPRA_LOGISTICA_UPDATE_CABECERA'
              const update = data.item
              update.etapa = etapa
              delete update.resumen
              delete update.resumenCotizacion
              if (etapa === 'ACEPTADO' || etapa === 'RECHAZADO') {
                update.fechaRespuesta = moment(new Date()).format('DD/MM/YYYY HH:mm:ss-05')
                if (etapa === 'RECHAZADO') {
                  console.log(result.value)
                  update.observacion = result.value
                }
              }
              await store.dispatch(service, update)
                .then(response => {
                  console.log(response)
                  this.$swal.close()
                  this.showToast('Operación Extitosa', 'Se cambio de etapa el pedido de compra', 'success')
                  this.listarRegistros()
                })
                .catch(error => {
                  this.$swal.close()
                  console.log(error)
                  this.showToast('Operación Error', error.errorMessage, 'danger')
                })
            },
          })
        }
      })
    },
    getDeletePedidoCompra(data) {
      console.log(data)
      this.$swal({
        title: 'Está seguro?',
        text: 'Va eliminar un registro!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Sí',
        cancelButtonText: 'No',
        customClass: {
          confirmButton: 'btn btn-danger',
          cancelButton: 'btn btn-primary ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.$swal({
            title: 'Eliminando Pedido de Compra',
            showCancelButton: false,
            showConfirmButton: false,
            allowOutsideClick: false,
            allowEscapeKey: false,
            onOpen: async () => {
              this.$swal.showLoading()
              const service = 'logistica/PEDIDO_COMPRA_LOGISTICA_DELETE'
              await store.dispatch(service, { id: data.item.idPedidoCompra })
                .then(response => {
                  console.log(response)
                  this.$swal.close()
                  this.showToast('Operación Extitosa', 'Se elimino el pedido de compra', 'success')
                  this.listarRegistros()
                })
                .catch(error => {
                  this.$swal.close()
                  console.log(error)
                  this.showToast('Operación Error', error.errorMessage, 'danger')
                })
            },
          })
        }
      })
    },
    formatearMoneda(valor, moneda) {
      // Convertir el número en cadena con dos decimales
      const valorFormateado = valor.toFixed(2)
      let respose = `S/. ${valorFormateado}`
      if (moneda === 'DOLARES') {
        respose = `$/. ${valorFormateado}`
      }
      return respose
      // Agregar el prefijo "S/." y retornar
    },
    deleteVenta(data) {
      this.$swal({
        title: 'Está seguro?',
        text: 'Va eliminar un registro!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Sí',
        cancelButtonText: 'No',
        customClass: {
          confirmButton: 'btn btn-danger',
          cancelButton: 'btn btn-primary ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          const partComprobante = data.item.numeroComprobante.split('-')
          const paramss = { nro_comprobante: partComprobante[1], serie: partComprobante[0], tipo_documento: data.item.tipoDocumento.tipoDocumentoId, venta_id: data.item.ventaId }
          this.$swal({
            title: 'Anulando en la Ose',
            showCancelButton: false,
            showConfirmButton: false,
            allowOutsideClick: false,
            allowEscapeKey: false,
            onOpen: async () => {
              this.$swal.showLoading()
              const service = 'comercial/VENTA_DELETE'
              await store.dispatch(service, paramss)
                .then(() => {
                  // this.items.forEach((item, index) => {
                  //   if (item.ventaId === data.item.ventaId) {
                  //     this.items[index].repuestaOse = response.respuesta_ose
                  //   }
                  // })
                  this.listarRegistros()
                  this.$swal.close()
                  this.showToast('Operación Extitosa', 'El comprobante se anulo', 'success')
                })
                .catch(error => {
                  this.$swal.close()
                  console.log(error)
                  this.showToast('Operación Error', error.errorMessage, 'danger')
                })
            },
          })
        }
      })
    },
    refetchData(response) {
      let message = 'El proceso se ha ejecutado satisfactoriamente.'
      if (response.message) {
        message = response.message
      }
      this.showToast('Operación Extitosa', message, 'success')
      this.listarRegistros()
    },
    getColorAvatar() {
      const max = 7
      const min = 1
      const status = Math.floor(Math.random() * ((max + 1) - min) + min)
      if (status === 1) return 'primary'
      if (status === 2) return 'danger'
      if (status === 3) return 'secondary'
      if (status === 4) return 'warning'
      if (status === 5) return 'info'
      if (status === 6) return 'success'
      return 'primary'
    },
    // deleteVenta
    // generarGuia
    // generarNota
    // getConsultaOse
    getConsultaOse(data) {
      console.info('Consulta', data)
      const paramss = { nro_comprobante: data.item.numero, serie: data.item.serie, tipo_documento: data.item.tipoComprobante, venta_id: 0, guia_remision_id: data.item.guiaRemisionId }
      this.$swal({
        title: 'Consultando en la Ose',
        showCancelButton: false,
        showConfirmButton: false,
        allowOutsideClick: false,
        allowEscapeKey: false,
        onOpen: async () => {
          this.$swal.showLoading()
          const service = 'comercial/VENTA_CONSULTA_OSE'
          await store.dispatch(service, paramss)
            .then(response => {
              this.items.forEach((item, index) => {
                if (item.ventaId === data.item.ventaId) {
                  this.items[index].repuestaOse = response.respuesta_ose
                }
              })
              this.$swal.close()
              this.showToast('Operación Extitosa', 'El comprobante esta enviado en la Ose', 'success')
            })
            .catch(error => {
              this.$swal.close()
              console.log(error)
              this.showToast('Operación Error', error.errorMessage, 'danger')
            })
        },
      })
    },
    chageStatusFilter() {
      console.log(this.statusFilter)
      this.listarRegistros()
    },
    avatarText,
    showToast,
    showError,
  },

}
</script>

  <style lang="scss" scoped>
  .per-page-selector {
    width: 90px;
  }

  .invoice-filter-select {
    min-width: 190px;

    ::v-deep .vs__selected-options {
      flex-wrap: nowrap;
    }

    ::v-deep .vs__selected {
      width: 100px;
    }
  }
  </style>

  <style lang="scss">
  @import '@core/scss/vue/libs/vue-select.scss';
  </style>

  <style>
  .w200{
    width: 350px !important;
  }

  </style>
